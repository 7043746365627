import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@mantine/core'
import styles from './PageHeader.module.scss'
import Button from '../common/Button'
import { X } from 'react-feather'
import { Link } from 'gatsby'

const PageHeader = ({ title, date, author, featuredImage }) => {
  const [backHover, setBackHover] = useState(false)
  const [scrolled, setScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const scrollY =
        document.body.scrollTop || document.documentElement.scrollTop
      if (scrollY >= 100) {
        setScrolled(true) // visible
      } else {
        setScrolled(false) // not visible
      }
      // lastScrollTop.current = scrollY <= 0 ? 0 : scrollY;
      // scrollY === 0 ? setOnTop(true) : setOnTop(false)
    }

    setScrolled(false)
    document.body.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <div className={styles.header}>
        <Link
          className={styles.backButton}
          to={`/stories`}
          onMouseOver={() => setBackHover(true)}
          onMouseOut={() => setBackHover(false)}
        >
          <X />
          {backHover && 'Close'}
        </Link>
        <div
          className={`${styles.headerContent} ${scrolled && styles.scrolled}`}
        >
          <div className={`${styles.heading} ${scrolled && styles.scrolled}`}>
            <h1>{title}</h1>
          </div>
        </div>
      </div>
    </>
  )
}

PageHeader.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string
}

export default PageHeader
