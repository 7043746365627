import React from 'react'
import _get from 'lodash/get'
import { graphql } from 'gatsby'
import Layout from '../components/common/Layout'
import PageHeader from '../components/singleStory/PageHeader'
import Article from '../components/singleStory/Article'
import Footer from '../components/common/Footer'
import Navigation from '../components/common/Nav'
import Meta from '../components/common/Meta'

export const SingleStoryTemplate = ({
  title,
  date,
  headings,
  featuredImage,
  body
}) => (
  <main>
    <PageHeader title={title} date={date} featuredImage={featuredImage} />

    <Article body={body} headings={headings} />
  </main>
)

// Export Default SinglePost for front-end
const SingleStory = ({ data: { post, allPosts } }) => {
  const thisEdge = allPosts.edges.find(edge => edge.node.id === post.id)
  return (
    <Layout
      meta={post.frontmatter.meta || false}
      title={post.frontmatter.title || false}
      socialMediaCard={post.frontmatter.featuredImage || false}
    >
      <SingleStoryTemplate
        {...post}
        {...post.frontmatter}
        body={post.html}
        nextPostURL={_get(thisEdge, 'next.fields.slug')}
        prevPostURL={_get(thisEdge, 'previous.fields.slug')}
      />
    </Layout>
  )
}

export default SingleStory

export const pageQuery = graphql`
  ## Query for SingleStory data
  ## Use GraphiQL interface (http://localhost:8000/___graphql)
  ## $id is processed via gatsby-node.js
  ## query name must be unique to this file
  query SingleStory($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      id
      headings {
        value
        depth
      }
      frontmatter {
        title
        template
        date(formatString: "MMMM Do, YYYY")
        featuredImage
        meta {
          title
          description
        }
      }
    }

    allPosts: allMarkdownRemark(
      filter: { fields: { contentType: { eq: "posts" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
        }
        next {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
        previous {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
`
